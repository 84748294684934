import {
	ActivityIndicator,
	Button,
	Dialog,
	DialogAction,
	DialogActions,
	DialogContent,
	Errors,
	Field,
	FieldDescription,
	Form,
	HiddenFields,
	Input,
	Label,
	LinkButton,
	Option,
	Select,
	TextField,
} from '@troon/ui';
import { useAction, useSubmission } from '@solidjs/router';
import { createEffect, createSignal, createUniqueId, Match, Show, Switch, For, createMemo } from 'solid-js';
import { phone } from 'phone';
import { Icon } from '@troon/icons';
import { gql, mutationAction, useMutation } from '../graphql';
import { useUser } from '../providers/user';
import type { JSXElement } from 'solid-js';

type Props = {
	buttonLabel?: JSXElement;
	onSuccess?: (phoneNumber: string) => void;
};

export function RequestPhoneNumber(props: Props) {
	const user = useUser();
	const [form, setForm] = createSignal<HTMLFormElement>();
	const formId = createUniqueId();

	const updateAction = useMutation(requestUpdate);
	const resendAction = useMutation(requestUpdate);
	const resendRequest = useAction(resendAction);

	const confirmAction = useMutation(confirm);
	const [inputNumber, setInputNumber] = createSignal(user()?.me.phoneNumber ?? '');
	const [showConfirmation, setShowConfirmation] = createSignal(false);

	const updateSubmission = useSubmission(updateAction);
	const resendSubmission = useSubmission(resendAction, ([data]) => data.get('__formId') !== formId);
	const confirmSubmission = useSubmission(confirmAction);

	const [confirmed, setConfirmed] = createSignal(false);

	createEffect(() => {
		const data = updateSubmission.result?.data?.updatePhoneNumber;
		if (data?.requiresConfirmation) {
			setShowConfirmation(!confirmSubmission?.result?.data?.confirmPhoneNumber);
			return;
		}

		if (data && !data.requiresConfirmation && props.onSuccess) {
			setConfirmed(true);
			props.onSuccess(phone(inputNumber()).phoneNumber!);
		}
	});

	const countryCode = createMemo(() => {
		const userPhone = user()?.me.phoneNumber;
		return userPhone ? (phone(userPhone).countryIso2 ?? 'US') : 'US';
	});

	createEffect(() => {
		const phoneNumber = confirmSubmission?.result?.data?.confirmPhoneNumber.phoneNumber;
		if (phoneNumber && props.onSuccess) {
			props.onSuccess(phoneNumber);
			setConfirmed(true);
		}
	});

	const displayNames = new Intl.DisplayNames('en-US', { type: 'region' });
	const compare = new Intl.Collator('en-US').compare;
	const countries = countryCodes
		.map((code) => [code, displayNames.of(code)] as [string, string])
		.sort((a, b) => compare(a[1], b[1]));

	return (
		<>
			<Form action={updateAction} document={requestMutation} ref={setForm} id={formId}>
				<div class="flex gap-4">
					<Field name="__countryCode" class="flex grow-0 basis-1/3 flex-col gap-1 sm:basis-1/4">
						<Label>Country</Label>
						<Select>
							<For each={countries}>
								{([code, name]) => (
									<Option value={code} selected={code === countryCode()}>
										{name}
									</Option>
								)}
							</For>
						</Select>
					</Field>
					<TextField name="input.phoneNumber" class="grow">
						<Label>Phone number</Label>
						<Input
							readonly={confirmed()}
							prefixElement={confirmed() ? <Icon name="circle-check" class="text-green-500" /> : <Icon name="phone" />}
							autocomplete="tel"
							inputMode="tel"
							class="ps-8"
							onInput={(e) => setInputNumber(e.target.value)}
							value={user()?.me.phoneNumber ?? ''}
						/>
					</TextField>
				</div>

				<Errors />

				<Show when={!confirmed()}>
					<Button type="submit" class="size-fit">
						{props.buttonLabel || 'Save'}
					</Button>
				</Show>
			</Form>
			<Dialog key="add-phone-number" open={showConfirmation()} onOpenChange={setShowConfirmation}>
				<DialogContent header="Confirm your phone number" headerLevel="h3">
					<Form action={confirmAction} document={confirmMutation} class="flex flex-col gap-4">
						<p>A confirmation code has been sent to {phone(inputNumber()).phoneNumber}.</p>
						<HiddenFields data={{ 'input.phoneNumber': phone(inputNumber()).phoneNumber }} />
						<TextField name="input.confirmationCode">
							<Label>Confirmation Code</Label>
							<Input inputMode="numeric" autocomplete="one-time-code" />
							<FieldDescription>
								<p>
									Didn’t receive a code?{' '}
									<LinkButton role="button" class="-mx-1.5" size="sm" onClick={() => setShowConfirmation(false)}>
										Change your phone number
									</LinkButton>{' '}
									or{' '}
									<LinkButton
										role="button"
										aria-disabled={resendSubmission.pending || !!resendSubmission.result?.data?.updatePhoneNumber}
										class="-mx-1.5"
										size="sm"
										onClick={() => {
											const data = new FormData(form());
											data.delete('__formId');
											resendRequest(data);
										}}
									>
										resend
									</LinkButton>
									<Switch>
										<Match when={resendSubmission.pending}>
											<span class="inline-flex">
												<ActivityIndicator class=" size-4" />
											</span>
										</Match>
										<Match when={resendSubmission.result?.data?.updatePhoneNumber}>
											<span>
												<Icon name="circle-check" class="size-4 text-green-500" />
												<span class="sr-only">Code resent</span>
											</span>
										</Match>
									</Switch>{' '}
									the confirmation code.
								</p>
							</FieldDescription>
						</TextField>

						<Errors />

						<DialogActions>
							<DialogAction type="submit">Confirm</DialogAction>
							<DialogAction type="button" appearance="transparent" onClick={() => setShowConfirmation(false)}>
								Cancel
							</DialogAction>
						</DialogActions>
					</Form>
				</DialogContent>
			</Dialog>
		</>
	);
}

const requestMutation = gql(`mutation updatePhoneNumber($input: UpdatePhoneNumberInput!) {
	updatePhoneNumber: updatePhoneNumberV2(input: $input) { requiresConfirmation }
}`);

const requestUpdate = mutationAction(requestMutation, {
	transform: (data) => ({
		input: {
			phoneNumber:
				phone(data.get('input.phoneNumber')! as string, { country: data.get('__countryCode') as string }).phoneNumber ??
				(data.get('input.phoneNumber') as string),
		},
	}),
	revalidates: ['loggedInUser'],
});

const confirmMutation = gql(`mutation confirmPhoneNumber($input: ConfirmPhoneNumberInput!) {
	confirmPhoneNumber(input: $input) { phoneNumber }
}`);

const confirm = mutationAction(confirmMutation, {
	revalidates: ['loggedInUser'],
});

const countryCodes = [
	'AD',
	'AE',
	'AF',
	'AG',
	'AI',
	'AL',
	'AM',
	'AO',
	'AQ',
	'AR',
	'AS',
	'AT',
	'AU',
	'AW',
	'AX',
	'AZ',
	'BA',
	'BB',
	'BD',
	'BE',
	'BF',
	'BG',
	'BH',
	'BI',
	'BJ',
	'BL',
	'BM',
	'BN',
	'BO',
	'BQ',
	'BR',
	'BS',
	'BT',
	'BV',
	'BW',
	'BY',
	'BZ',
	'CA',
	'CC',
	'CD',
	'CF',
	'CG',
	'CH',
	'CI',
	'CK',
	'CL',
	'CM',
	'CN',
	'CO',
	'CR',
	'CU',
	'CV',
	'CW',
	'CX',
	'CY',
	'CZ',
	'DE',
	'DJ',
	'DK',
	'DM',
	'DO',
	'DZ',
	'EC',
	'EE',
	'EG',
	'EH',
	'ER',
	'ES',
	'ET',
	'FI',
	'FJ',
	'FK',
	'FM',
	'FO',
	'FR',
	'GA',
	'GB',
	'GD',
	'GE',
	'GF',
	'GG',
	'GH',
	'GI',
	'GL',
	'GM',
	'GN',
	'GP',
	'GQ',
	'GR',
	'GS',
	'GT',
	'GU',
	'GW',
	'GY',
	'HK',
	'HM',
	'HN',
	'HR',
	'HT',
	'HU',
	'ID',
	'IE',
	'IL',
	'IM',
	'IN',
	'IO',
	'IQ',
	'IR',
	'IS',
	'IT',
	'JE',
	'JM',
	'JO',
	'JP',
	'KE',
	'KG',
	'KH',
	'KI',
	'KM',
	'KN',
	'KP',
	'KR',
	'KW',
	'KY',
	'KZ',
	'LA',
	'LB',
	'LC',
	'LI',
	'LK',
	'LR',
	'LS',
	'LT',
	'LU',
	'LV',
	'LY',
	'MA',
	'MC',
	'MD',
	'ME',
	'MF',
	'MG',
	'MH',
	'MK',
	'ML',
	'MM',
	'MN',
	'MO',
	'MP',
	'MQ',
	'MR',
	'MS',
	'MT',
	'MU',
	'MV',
	'MW',
	'MX',
	'MY',
	'MZ',
	'NA',
	'NC',
	'NE',
	'NF',
	'NG',
	'NI',
	'NL',
	'NO',
	'NP',
	'NR',
	'NU',
	'NZ',
	'OM',
	'PA',
	'PE',
	'PF',
	'PG',
	'PH',
	'PK',
	'PL',
	'PM',
	'PN',
	'PR',
	'PS',
	'PT',
	'PW',
	'PY',
	'QA',
	'RE',
	'RO',
	'RS',
	'RU',
	'RW',
	'SA',
	'SB',
	'SC',
	'SD',
	'SE',
	'SG',
	'SH',
	'SI',
	'SJ',
	'SK',
	'SL',
	'SM',
	'SN',
	'SO',
	'SR',
	'SS',
	'ST',
	'SV',
	'SX',
	'SY',
	'SZ',
	'TC',
	'TD',
	'TF',
	'TG',
	'TH',
	'TJ',
	'TK',
	'TL',
	'TM',
	'TN',
	'TO',
	'TR',
	'TT',
	'TV',
	'TW',
	'TZ',
	'UA',
	'UG',
	'UM',
	'US',
	'UY',
	'UZ',
	'VA',
	'VC',
	'VE',
	'VG',
	'VI',
	'VN',
	'VU',
	'WF',
	'WS',
	'YE',
	'YT',
	'ZA',
	'ZM',
	'ZW',
];
